import React from "react"

import * as contactFormStyles from "../styles/components/contact-form.module.scss"

import { PrimaryButtonInput } from "../styles/components/shared-styles"

const ContactForm = () => {
  return (
    <div className={contactFormStyles.container}>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <label>First Name</label>
        <input
          type="text"
          name="firstname"
          placeholder="e.g. Connor"
          required
        />
        <label>Last Name</label>
        <input type="text" name="lastname" placeholder="e.g. Black" required />
        <label>Email Address</label>
        <input
          type="email"
          name="emailaddress"
          placeholder="e.g. connor.b645@gmail.com"
          required
        />
        <label>Message</label>
        <textarea
          name="message"
          placeholder="How can I help?"
          rows={10}
          required
        />
        <div className={contactFormStyles.submitContainer}>
          <PrimaryButtonInput type="submit" value="Send" />
        </div>
      </form>
    </div>
  )
}

export default ContactForm
