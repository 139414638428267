import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/contact-form"

import * as contactStyles from "../styles/pages/contact.module.scss"
import Head from "../components/head"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <Head title="Contact" />
        <h1 className={contactStyles.heading}>Contact Me</h1>
        <ContactForm />
      </Layout>
    </div>
  )
}

export default ContactPage
